<template>
  <div>
    <Header></Header>
    <div class="register" id="content">
      <div class="login-img_bg" id="login_img"/>
      <div style="width:1140px;height:100%;margin:0 auto;position: relative;">
        <a-form v-if="oneStep" :form="form" class="login-form" @submit="handleSubmitFirst" id="login">
          <h3 class="title">用户注册</h3>
          <a-tabs default-active-key="1" @change="registerCodeCheck">
            <a-tab-pane key="1" tab="邮箱注册">
              <a-form-item class="emailForm">
                <a-input
                  size="large"
                  type="text"
                  placeholder="请输入邮箱"
                  v-decorator="[
                              'email',
                              {rules: [{ required: true, message: '请输入邮箱！' },{type:'email', message:'邮箱地址格式错误！' }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}
                          ]"
                >
                  <img class="img1" :src="user" slot="prefix" alt=""/>
                </a-input>
              </a-form-item>
              <a-row :gutter="16">
                <a-col class="gutter-row" :span="14">
                  <a-form-item>
                    <a-input size="large" type="text" placeholder="请输入验证码"
                             v-decorator="['captcha', {rules: [{ required: true, message: '请输入验证码！' }], validateTrigger: 'blur'}]"></a-input>
                  </a-form-item>
                </a-col>
                <a-col class="gutter-row" :span="10">
                  <a-button
                    class="getCaptcha"
                    tabindex="-1"
                    :disabled="state.emailSendBtn"
                    @click.stop.prevent="getCaptcha"
                    v-text="!state.emailSendBtn && '获取验证码' || (state.emailTime+' s')"
                  ></a-button>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane key="2" tab="手机注册" force-render>
              <a-form-item class="emailForm">
                <a-input size="large" type="text" placeholder="请输入手机号"
                         v-decorator="['mobile', {rules: [{ required: true, message: '请输入手机号！' },{ pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' }], validateTrigger: 'change'}]">
                  <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                </a-input>
              </a-form-item>
              <a-row :gutter="16">
                <a-col class="gutter-row" :span="14">
                  <a-form-item>
                    <a-input size="large" type="text" placeholder="请输入验证码"
                             v-decorator="['captcha', {rules: [{ required: true, message: '请输入验证码！' }], validateTrigger: 'blur'}]">
                      <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col class="gutter-row" :span="10">
                  <a-button
                    class="getCaptcha"
                    tabindex="-1"
                    :disabled="state.smsSendBtn"
                    @click.stop.prevent="getPhoneCaptcha"
                    v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')"
                  ></a-button>
                </a-col>
              </a-row>
            </a-tab-pane>
          </a-tabs>
          <a-button
            class="submit"
            size="large"
            type="primary"
            htmlType="submit"
            :loading="state.loginBtn"
          >下一步
          </a-button>
          <div class="user-login"> 已有账户，直接
            <router-link class="login" :to="{ name: 'login' }">登录</router-link>
          </div>
        </a-form>
        <a-form v-if="twoStep" :form="form" class="login-form" @submit="handleSubmit" id="login">
          <h3 class="title">用户注册</h3>
          <!-- <a-tabs
          :activeKey="customActiveKey"
          :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
          @change="handleTabClick"
          >
          <a-tab-pane key="tab1" tab="邮箱注册"> -->
          <a-form-item class="emailForm">
            <a-input
              v-if="registerType=='1'"
              size="large"
              type="text"
              placeholder="邮箱账号"
              v-decorator="[
                        'email',
                        {rules: [{ required: true,type:'email', message:'邮箱地址格式错误！' }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}
                    ]"
            >
              <img class="img1" :src="user" slot="prefix" alt=""/>
            </a-input>
            <a-input v-if="registerType=='2'" size="large" type="text" placeholder="请输入手机号"
                     v-decorator="['mobile', {rules: [{ pattern: /^1[34578]\d{9}$/, message: '请输入正确的手机号' }], validateTrigger: 'change'}]">
              <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
            </a-input>
          </a-form-item>
          <div class="roleSelect" v-if="false">
            <a-radio-group v-model="value" @change="onChange">
              <a-radio :value="2">
                我是老师
              </a-radio>
              <a-radio :value="1">
                我是学生
              </a-radio>
            </a-radio-group>
          </div>
          <a-form-item has-feedback>
            <a-input
              placeholder="输入密码"
              v-decorator="[ 'password', { rules: [ { required: true, message: '请输入密码', }, { validator: validateToNextPassword}]}]"
              type="password"
            >
              <img class="img2" :src="mima" slot="prefix" alt=""/>
            </a-input>
          </a-form-item>
          <a-form-item has-feedback>
            <a-input
              placeholder="请确认密码"
              v-decorator="[ 'confirm', { rules: [ { required: true, message: '两次输入的密码不匹配',},{ validator: compareToFirstPassword }]}]"
              type="password" @blur="handleConfirmBlur"
            >
              <img class="img2" :src="mima" slot="prefix" alt=""/>
            </a-input>
          </a-form-item>
          <a-button
            class="submit"
            size="large"
            type="primary"
            htmlType="submit"
            :loading="state.loginBtn"
            :disabled="state.loginBtn"
          >提交
          </a-button>
          <div class="user-login"> 已有账户，直接
            <router-link class="login" :to="{ name: 'login' }">登录</router-link>
          </div>
          <!-- </a-tab-pane> -->
          <!-- <a-tab-pane key="tab2" tab="手机号注册">
          <a-form-item>
              <a-input size="large" type="text" placeholder="请输入手机号" v-decorator="['mobile', {rules: [{ required: true, pattern: /^1[34578]\d{9}$/, message: '请输入正确的手机号' }], validateTrigger: 'change'}]">
              <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
              </a-input>
          </a-form-item>

          <a-row :gutter="16">
              <a-col class="gutter-row" :span="14">
              <a-form-item>
                  <a-input size="large" type="text" placeholder="验证码" v-decorator="['captcha', {rules: [{ required: true, message: '请输入验证码！' }], validateTrigger: 'blur'}]">
                  <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                  </a-input>
              </a-form-item>
              </a-col>
              <a-col class="gutter-row" :span="10">
              <a-button
                  class="getCaptcha"
                  tabindex="-1"
                  :disabled="state.smsSendBtn"
                  @click.stop.prevent="getCaptcha"
                  v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')"
              ></a-button>
              </a-col>
          </a-row>
          </a-tab-pane> -->
          <!-- </a-tabs> -->
        </a-form>
        <a-form v-if="threeStep" :form="form" class="login-form" @submit="handleLogin" id="login">
          <!-- <a-alert v-if="isLoginSuccess" type="success" showIcon style="margin-bottom: 24px;" message="注册成功" />  -->
          <div class="success">
            <img class="successImg" src="../../assets/img/58.png" alt=""/>
            <span class="successText">注册成功</span>
          </div>
          <a-form-item style="margin-top:40px;">
            <a-input
              v-if="registerType=='1'"
              size="large"
              type="text"
              placeholder="请输入邮箱"
              v-decorator="['email', {rules: [{ required: true, type: 'email', message: '请输入正确的邮箱格式' }], validateTrigger: ['change']}]"
            >
              <img class="img1" :src="user" slot="prefix" alt=""/>
            </a-input>
            <a-input v-if="registerType=='2'" size="large" type="text" placeholder="请输入手机号"
                     v-decorator="['mobile', {rules: [{ pattern: /^1[34578]\d{9}$/, message: '请输入正确的手机号' }], validateTrigger: 'change'}]">
              <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input-password
              size="large"
              placeholder="请输入密码"
              v-decorator="[
                            'password',
                            {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur'}
                        ]"
            >
              <img class="img2" :src="mima" slot="prefix" alt=""/>
            </a-input-password>
          </a-form-item>
          <a-button
            class="submit"
            size="large"
            type="primary"
            htmlType="submit"
            :loading="state.loginBtn"
            :disabled="state.loginBtn"
          >登录
          </a-button>
          <div class="agree">
            <span class="text1">登录即代表您同意</span>
            <span class="text2" @click="goSecret">用户隐私政策</span>
          </div>
        </a-form>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import user from '../../assets/img/55.png'
import mima from '../../assets/img/56.png'
import Header from '@/components/tools/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
import md5 from '@/utils/MD5.js'

export const getUserInfo = () => {
  const userStr = window.localStorage.getItem('user_info')
  let user = null
  try {
    user = JSON.parse(userStr)
  } catch (e) {
  }
  return user
}

export const clearUserInfo = () => {
  window.localStorage.removeItem('user_info')
}

const setUserInfo = user => {
  let userStr = null
  try {
    userStr = JSON.stringify(user)
  } catch (e) {
  }
  userStr && window.localStorage.setItem('user_info', userStr)
}
export default {
  name: 'register',
  data () {
    return {
      md5,
      user,
      mima,
      encryptionKey: '',
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginSuccess: true,
      form: this.$form.createForm(this),
      form: {
        username: '',
        password: ''
      },
      state: {
        time: 180,
        emailTime: 180,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false,
        emailSendBtn: false
      },
      oneStep: true,
      twoStep: false,
      threeStep: false,
      value: 2, // 教师还是学生选择
      verificationCode: '', // 验证码
      registerType: '1', // 1 默认是邮箱 2 按照手机
      client_id: this.$clientId,
      client_secret: this.$clientSecret,
      userId: ''
    }
  },
  created () {
    this.form = this.$form.createForm(this, { name: 'register' })
  },
  mounted () {
    this.form.resetFields()
    this.getPublicSecret()
    this.getHeight()
  },
  methods: {
    getPublicSecret () {
      // 获取加密公钥
      const _this = this
      const param = {}
      const url = this.$loginServer + 'auth/res/getPublicSecret'
      axios({
        url: url,
        method: 'GET',
        data: param
      }).then(res => {
        if (res && res.data && res.data.resultCode === '0') {
          // 获取加密公钥
          _this.encryptionKey = res.data.resultData
          window.localStorage.setItem('encryptionKey', _this.encryptionKey)
          // console.log('获取加密公钥', _this.encryptionKey)
        }
      })
    },
    goSecret () {
      window.open('http://www.han-sky.com/privacy.html', '__blank')
    },
    registerCodeCheck (key) {
      this.registerType = key
      console.log('注册类型：', this.registerType)
    },
    handleTabClick (key) {
      this.customActiveKey = key
    },
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    // 获取验证码
    getCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state, $message, $notification } = this
      validateFields(['email'], { force: true },
        (err, values) => {
          if (!err) {
            // 邮箱注册-发送验证码
            // 参数
            const hide = $message.loading('验证码发送中..', 0)
            const interval = window.setInterval(() => {
              // console.log('邮箱注册-发送验证码', values)
              if (state.emailTime-- <= 0) {
                state.emailTime = 180
                state.emailSendBtn = false
                window.clearInterval(interval)
              }
            }, 1000)
            const emailCodeParam = {
              client_id: this.client_id,
              client_secret: this.client_secret,
              email: values.email,
              sendType: '1',
              time: (new Date()).valueOf()
            }
            const sign = md5.paramMd5(emailCodeParam, 'client_secret', 'hytcebook.7,f@e')
            emailCodeParam.sign = sign

            const _this = this
            _this.$http.post(
              _this.$loginServer + 'auth/res/encrypt/sendEmailVerCode',
              emailCodeParam
            ).then(res => {
              if (res && res.data && res.data.resultData && res.data.resultCode === '0') {
              //  console.log('邮箱注册-发送验证码', res.data)
                state.emailSendBtn = true
                setTimeout(hide, 2500)
                $notification.success({
                  message: '提示',
                  description: '验证码获取成功,请您到注册的邮箱查看',
                  duration: 5
                })
              } else {
                clearInterval(interval)
                setTimeout(hide, 500)
                $notification.error({
                  message: '提示',
                  description: res.data.resultMessage,
                  duration: 5
                })
              }
            }).catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.emailTime = 180
              state.emailSendBtn = false
              // this.requestFailed(err)
            })
          }
        })
    },
    getPhoneCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state, $message, $notification } = this
      validateFields(['mobile'], { force: true },
        (err, values) => {
          if (!err) {
            const hide = $message.loading('验证码发送中..', 0)
            const interval = window.setInterval(() => {
              // console.log('手机注册-发送验证码', values)
              if (state.time-- <= 0) {
                state.time = 180
                state.smsSendBtn = false
                window.clearInterval(interval)
              }
            }, 1000)
            // 手机注册-发送验证码
            // 参数
            const emailCodeParam = {
              client_id: this.client_id,
              client_secret: this.client_secret,
              phone: values.mobile,
              sendType: '1',
              time: (new Date()).valueOf()
            }
            const sign = md5.paramMd5(emailCodeParam, 'client_secret', 'hytcebook.7,f@e')
            emailCodeParam.sign = sign
            const _this = this
            _this.$http.post(
              _this.$loginServer + 'auth/res/encrypt/sendPhoneVerCode',
              emailCodeParam
            ).then(res => {
              if (res && res.data && res.data.resultData && res.data.resultCode === '0') {
                // console.log('手机注册-发送验证码', res.data)
                state.smsSendBtn = true
                setTimeout(hide, 2500)
                $notification.success({
                  message: '提示',
                  description: '验证码获取成功,请您手机查看',
                  duration: 5
                })
              } else {
                clearInterval(interval)
                setTimeout(hide, 500)
                $notification.error({
                  message: '提示',
                  description: res.data.resultMessage,
                  duration: 5
                })
              }
            }).catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 180
              state.smsSendBtn = false
              // this.requestFailed(err)
            })
          }
        })
    },
    requestFailed (err) {
      this.$notification.error({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
      this.registerBtn = false
    },
    handleConfirmBlur (e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    validateToNextPassword (rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    compareToFirstPassword (rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        callback('两次输入的密码不一致')
      } else {
        callback()
      }
    },
    onChange (e) {
      console.log('radio checked', e.target.value)
      this.value = e.target.value
    },
    // 验证码注册
    handleSubmitFirst (e) {
      e.preventDefault()
      const _th = this
      this.form.validateFieldsAndScroll((err, values) => {
        if (_th.registerType === '1') {
          this.handleValidation('email', err, values)
        } else {
          this.handleValidation('phone', err, values)
        }
      })
    },
    handleValidation (type, err, values) {
      if (!err || (type === 'email' && !err.email) || (type === 'phone' && !err.phone)) {
        const param = {
          client_id: this.$clientId,
          client_secret: this.$clientSecret,
          code: values.captcha,
          // 根据type设置不同的字段
          time: (new Date()).valueOf()
        }
        if (type === 'email') {
          param.email = values.email.trim()
        } else {
          param.phone = values.mobile
        }
        const sign = md5.paramMd5(param, 'client_secret', this.$clientSecret)
        param.sign = sign

        // 使用配置的URL进行请求
        if (type === 'email') {
          this.performVerification(this.$loginServer + 'auth/res/encrypt/verifyEmailVerCode', param)
        } else {
          this.performVerification(this.$loginServer + 'auth/res/encrypt/verifyPhoneVerCode', param)
        }
      }
    },

    performVerification (url, param) {
      this.$http.post(url, param)
        .then(res => {
          if (res && res.data && res.data.resultCode === '0') {
            this.oneStep = false
            this.twoStep = true
            this.verificationCode = param.code
          } else {
            // 国际化处理错误信息
            this.$notification.error({
              message: '提示',
              description: res.data.resultMessage || '验证失败，请重试',
              duration: 5
            })
          }
        })
        .catch(error => {
          // 网络请求错误处理
          console.error('网络请求失败:', error)
          this.$notification.error({
            message: '网络错误',
            description: '请检查网络连接',
            duration: 5
          })
        })
    },
    // 注册方法
    handleSubmit (e) {
      e.preventDefault()
      const { form: { validateFields }, state, $message, $notification } = this
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          // console.log('注册参数: ', values)
          const hide = $message.loading('注册用户中..', 0)
          const param = {
            client_id: this.client_id,
            client_secret: this.client_secret,
            code: this.verificationCode,
            password: values.password,
            confirmPassword: values.password,
            time: (new Date()).valueOf(),
            params: {
              successStrategyType: 0,
              roleType: this.value
            }
          }
          if (this.registerType == '1') {
            param.email = values.email
          } else {
            param.phone = values.mobile
          }
          // console.log('用户注册-接口参数', param)
          const sign = md5.paramMd5(param, 'client_secret', 'hytcebook.7,f@e')
          param.sign = sign
          const url = this.registerType == '1' ? 'auth/res/v2/emailCodeRegister' : 'auth/res/v2/phoneCodeRegister'
          this.$http.post(
            this.$loginServer + url,
            param
          ).then(res => {
            if (res && res.data && res.data.resultCode === '0') {
              // console.log('用户注册', res.data)
              setTimeout(hide, 1500)
              $notification.success({
                message: '提示',
                description: '注册用户成功'
              })
              this.twoStep = false
              this.threeStep = true
            } else {
              setTimeout(hide, 100)
              $notification.error({
                message: '提示',
                description: res.data.resultMessage,
                duration: 5
              })
            }
          })
        }
      })
    },
    getClass () {
      const param = {
        access_token: '',
        userId: this.userId
      }
      axios({
        url: this.$baseServer + 'ebook/class/common/getMyClassInfo',
        method: 'POST',
        data: param
      }).then(res => {
        setUserInfo(Object.assign(getUserInfo(), {
          class: res.data.resultData || []
        }))
      })
    },
    // 注册成功 登录
    handleLogin (e) {
      const hide = this.$message.loading('登录中..', 0)
      e.preventDefault()
      const _this = this
      _this.form.validateFieldsAndScroll((err, values) => {
        const param = {
          client_id: this.client_id,
          client_secret: this.client_secret,
          password: values.password,
          time: (new Date()).valueOf()
        }
        if (this.registerType == '1') {
          param.username = values.email
        } else {
          param.username = values.mobile
        }
        const sign = md5.paramMd5(param, 'client_secret', 'hytcebook.7,f@e')
        param.sign = sign
        const _this = this
        this.$http.post(
          _this.$loginServer + 'auth/res/v2/login',
          param
        ).then(res => {
          if (res && res.data && res.data.resultCode === '0') {
            // console.log('用户登录', res.data)
            setTimeout(hide, 500)
            _this.$notification.success({
              message: '提示',
              description: '登录成功'
            })
            _this.userId = res.data.resultData.user.id
            _this.getClass()
            setUserInfo(Object.assign(res.data.resultData.user, {
              user: [],
              access_token: res.data.resultData.tokenInfo.access_token
            }))
            localStorage.setItem('access_token', res.data.resultData.tokenInfo.access_token)
            localStorage.setItem('userId', res.data.resultData.user.id)
            const role = res.data.resultData.user.roleList.find(item => {
              return item.id == 5
            })
            if (role) {
              _this.$router.push({ name: 'homePage' })
              // window.open(_this.$stuUrl + 'page/qt/tea/index.html', '_blank')
              window.location.href = _this.$stuUrl + 'page/qt/tea/index.html'
            } else {
              _this.$router.push({ name: 'homePage' })
              // window.open(_this.$teaUrl + 'page/qt/stu/index.html', '_blank')
              window.location.href = _this.$stuUrl + 'page/qt/stu/index.html'
            }
          } else {
            setTimeout(hide, 500)
            clearUserInfo()
            _this.$notification.error({
              message: '提示',
              description: res.data.resultMessage
            })
          }
        })
      })
    },
    getUserInfo,
    setUserInfo,
    getHeight () {
      const windowHeight = window.innerHeight - 60 - 293
      document.getElementById('content').style.height = windowHeight + 'px'
      if (windowHeight - 480 > 0) {
        document.getElementById('login').style.marginTop = (windowHeight - 480) / 2 + 'px'
      } else {
        document.getElementById('login').style.marginTop = 10 + 'px'
      }
      if (windowHeight - 370 > 0) {
        document.getElementById('login_img').style.marginTop = (windowHeight - 370) / 2 + 'px'
      } else {
        document.getElementById('login_img').style.marginTop = 65 + 'px'
      }
      document.getElementById('login_img').style.marginLeft = (windowHeight) / 1.5 + 'px'
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="less" scoped>
.ant-layout-header, .ant-layout-content, .ant-layout-footer {
  margin: 0;
  padding: 0;
  background: #FFF;
}

.ant-layout-header {
  height: 64px;
  border-bottom: 1px solid rgba(61, 61, 61, .15);
  box-sizing: border-box;
}

.register {
  //max-width:1920px;
  min-height: 500px;
  background-image: url("../../assets/img/login-bg-imp.png");
  background-size: 100% 100%;
  height: auto;
  overflow: auto;

  .login-img_bg {
    width: 119px;
    height: 370px;
    position: absolute;
    background-image: url('../../assets/img/login-bg-text.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 160px;
    margin-left: 480px;
  }

  .login-form {
    width: 400px;
    height: 480px;
    /* background: url("../assets/houTaiKuang.png"); */
    background-size: 100% 100%;
    padding: 40px 50px;
    position: absolute;
    margin-top: 120px;
    right: 0px;
    background-image: url("../../assets/img/bg_login_form.png");

    .title {
      text-align: center;
      font-size: 25px;
    }

    .img1 {
      width: 17px;
      height: 14px;
    }

    .img2 {
      width: 16px;
      height: 16px;
    }
  }

  .emailForm {
    margin-top: 20px;
  }

  .roleSelect {
    height: 40px;
    margin-top: 10px;
  }

  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 50px;
  }

  /* 提交注册按钮 */

  .submit {
    width: 100%;
    height: 36px;
    font-size: 16px;
  }

  .user-login {
    text-align: center;
    z-index: 2;
    padding-top: 15px;
    padding-bottom: 20px;

    .login {
      color: blue;
    }
  }
}

/deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 35px;
}

.getCaptcha {
  width: 115px;
  height: 40px;
  font-size: 16px;
  background: #DB5647;
  color: #fff;
}

.agree {
  margin-top: 60px;
  text-align: center;
  font-size: 12px;

  .text1 {
    color: #BCB8B3;
  }

  .text2 {
    color: #000;
    cursor: pointer;
  }
}

.success {
  margin-top: 40px;
  text-align: center;

  .successImg {
    width: 48px;
    height: 48px;
    margin-top: -10px;
  }

  .successText {
    display: inline-block;
    color: #000;
    font-weight: 500;
    font-size: 22px;
    margin-left: 20px;
  }
}

/deep/ .ant-tabs-nav .ant-tabs-tab {
  font-size: 18px;
  margin: 0px;
  padding: 12px 30px;
}

/deep/ .ant-tabs-nav-container {
  text-align: center;
}

/deep/ .ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 20px;
  z-index: 1;
  box-sizing: border-box;
  width: 90px !important;
  height: 2px;
  //background-color: #db5647;
  transform-origin: 0 0;
}
</style>
