<template>
  <div>
    <a-row class="top">
      <a-col :span="7" class="left">
        <div class="logo" @click="logoGo"></div>
      </a-col>
      <a-col :span="12"  class="center">
      </a-col>
        <a-col :span="5"  class="right">
            <!-- <a-button class="login" @click="login">登录</a-button>
            <a-button class="regin" @click="register">注册</a-button> -->
        </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      searchValue: '', // 搜索内容
      routeKsy: 0,
      current: 0,
      titleList: [
        { name: '首页', key: 'home', id: 0 },
        { name: '产品', key: 'product', id: 1 },
        { name: '考场', key: 'examinationRoom', id: 2, list: [{ name: '高考真题', key: 'testPaper', id: 31 }, { name: '政策解析', key: 'examinationRoom', id: 32 }] },
        // {name: '学堂', key: 'studyRoom',id: 3},
        { name: '书屋', key: 'bookRoom', id: 4, list: [{ name: '经典阅读', id: 31, key: 'bookRoom' }, { name: '书目推荐', id: 32, key: 'programRecommend' }] },
        { name: '资讯', key: 'consulting', id: 6 },
        { name: '活动', key: 'active', id: 5 }
        // { name: '大赛', key: 'competition', id: 7 }
      ]
    }
  },
  methods: {
    changeTitle1 (item, index) {
      console.log(this)
      this.current = index
      sessionStorage.setItem('routeIndex', this.current)
      this.$router.push(item.key)
    },
    changePage (i) {
      this.$router.push(i.key)
    },
    login () { // 登录
      this.$router.push({ name: 'login' })
    },
    register () { // 注册
      this.$router.push({ name: 'register' })
    },
    logoGo () {
      this.$router.push({ name: 'homePage' })
      this.$nextTick(() => {
        this.current = 0
        sessionStorage.setItem('routeIndex', 0)
      })
    }
  },
  watch: {
    $route: function () {
      this.routeKsy = sessionStorage.getItem('routeIndex')
      if (this.routeKsy) {
        this.current = this.routeKsy
      } else {
        this.current = 0
      }
    }
  }
}
</script>

<style scoped="scoped" lang="less">

    ul,li{
        list-style: none;
        margin: 0;
        padding: 0;
    }
  //我们隐藏了 antdvue 导航条的底部线条 并且给了新的高度
    .ant-menu-horizontal{
        border: none;
        line-height: 61px;
    }
    /deep/.ant-layout-header{
        background: #fff;
      }
    .top{
        width: 1140px;
        margin: auto;
        line-height: 64px;
        height: 64px;
        .left{
          .logo{
            cursor: pointer;
            margin-top: 10px;
            width: 111px;
            height: 39px;
            background: url('../../assets/logo.png');
            background-size: 100% 100%;
          }
        }
        .center{
            .titleUl{
                height: 60px;
                line-height: 60px;
                .titleLi{
                    display: inline-block;
                    font-size: 16px;
                    color: #000;
                    width: 80px;
                    height: 60px;
                    line-height: 50px;
                    text-align: center;
                    cursor: pointer;
                    position: relative;
                    &:hover{
                        color: #db5647;
                        .secTitle{
                          position: absolute;
                          display: block;
                          top: 60px;
                          left: -15px;
                          width: 120px;
                          height: 80px;
                          background: #fff;
                          text-align: center;
                          font-size: 16px;
                          color: #000;
                          z-index: 99;
                          .secLi{
                            height: 40px;
                            line-height: 40px;
                            width: 120px;
                            &:hover{
                              background-color: #db5647;
                              color: #fff;
                            }
                          }
                        }
                    }
                    .secTitle{
                      position: absolute;
                      display: none;
                      top: 60px;
                      left: 0;
                    }
                }
            }
        }
        .right{
          text-align: right;
            .login{
                width: 76px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 22px;
                border: 1px solid #DB5647;
                color: #Db5647;
                font-size: 14px;
            }
            .regin{
                width: 76px;
                height: 32px;
                background: #DB5647;
                border-radius: 22px;
                border: 1px solid #DB5647;
                color: #fff;
                font-size: 14px;
                margin-left: 15px;
            }
        }
    }
    .activeLi{
        color: #DB5647;
        font-size: 16px;
        display: inline-block;
        width: 70px;
        text-align: center;
        position: relative;
        cursor: pointer;
            &::after {
                width: 20px;
                height: 2px;
                content: "";
                position: absolute;
                top: 90%;
                left: 35%;
                background-color: #db5647;
            }
    }
  .activeLi{
        color: #DB5647;
        font-size: 16px;
        display: inline-block;
        width: 70px;
        text-align: center;
        position: relative;
        cursor: pointer;
            &::after {
                width: 20px;
                height: 2px;
                content: "";
                position: absolute;
                top: 90%;
                left: 35%;
                background-color: #db5647;
            }
    }
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  // transform: translateX(30px);
  opacity: 0;
}
</style>
